<template>
  <div class="pcm-wrapper" id="pcm-wrapper">
    <div class="logo"></div>
    <div class="contactBox">
      <div @click="toBusiness"></div>
      <div @click="toTg"></div>
    </div>
    <div class="figureBox">
      <div class="figure"></div>
    </div>
    <div class="logoText"></div>
    <div class="downloadBox">
      <img class="qrcodeBox" :src="qrImg" alt="" />
    </div>
  </div>
</template>

<script>
import autofit from "autofit.js";
autofit.init();
export default {
  props: ["qrImg"],
  mounted() {
    autofit.init(
      {
        dh: 1080,
        dw: 1920,
        el: "#pcm-wrapper",
        resize: true,
      },
      false
    ); // 可关闭控制台运行提示输出
  },
  methods: {
    toBusiness() {
      window.open("https://t.me/laiango2naduo");
    },
    toTg() {
      window.open("https://t.me/loliidao");
    },
    toPotato() {
      window.open("https://dlptm.org/loliidao");
    },
  },
};
</script>

<style lang="scss" scoped>
.pcm-wrapper {
  width: 100vw;
  height: 100vh;
  background: url("./../../../assets/images/pc/bg.webp") no-repeat;
  background-size: 100% 100%;
  .logo {
    height: 106px;
    width: 320px;
    background: url("./../../../assets/images/pc/logo.webp") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 75px;
    left: 100px;
  }
  .contactBox {
    position: fixed;
    right: 100px;
    top: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    z-index: 999;
    height: 193px;
    width: 200px;
    background: url("./../../../assets/images/pc/contactBox.png") no-repeat;
    background-size: 100% 100%;
    div {
      height: 96px;
      width: 200px;
      cursor: pointer;
    }
  }
  .figureBox {
    height: 867px;
    width: 1000px;
    position: absolute;
    right: 0;
    bottom: 100px;
    background: url("../../../assets/images/pc/figureBg.webp") no-repeat;
    background-size: 100% 100%;
    .figure {
      height: 932px;
      width: 600px;
      position: absolute;
      top: 0;
      left: 50%;
      z-index: -1;
      transform: translate(-50%, 0);
      background: url("../../../assets/images/pc/figure.webp") no-repeat;
      background-size: 100% 100%;
    }
  }
  .logoText {
    height: 492px;
    width: 890px;
    position: absolute;
    left: 120px;
    top: 240px;
    background: url("../../../assets/images/pc/logoText.webp") no-repeat;
    background-size: 100% 100%;
  }
  .downloadBox {
    position: absolute;
    left: 100px;
    bottom: 70px;
    height: 227.6px;
    width: 877px;
    background: url("./../../../assets/images/pc/installSuggestion.webp")
      no-repeat;
    background-size: 100% 100%;
    .qrcodeBox {
      height: 150px;
      width: 150px;
      background-color: white;
      position: absolute;
      left: 25px;
      top: 38px;
    }
  }
}
</style>
