<template>
  <div class="mobilePage">
    <div class="main">
      <!-- <img class="bg" src="./../../../assets/images/mobile1/bg.webp" /> -->
      <div class="headerBox">
        <div class="logo"></div>
        <div class="contactBox">
          <div @click="toTg"></div>
          <div @click="toBusiness"></div>
        </div>
      </div>
      <div class="bottomBox">
        <div class="iosBtn" @click="getApkInfo()"></div>
        <div class="androidBtn" @click="getApkInfo()"></div>
      </div>
      <div class="bottomText"></div>
    </div>
    <!-- 安卓提示框 -->
    <van-overlay :show="androidPromptShow" z-index="999" class="androidOverlay">
      <div class="androidPopupBox" @click.stop>
        <div class="pHeader">
          <div class="pBack" @click="androidPromptShow = false"></div>
          <div class="pTitle"></div>
        </div>
        <div class="pMain" @touchmove.stop>
          <div class="overlayContent">
            <div
              class="btnBox"
              v-for="item in 9"
              :key="item"
              @click="showPlan(item)"
            ></div>
          </div>
        </div>
      </div>
    </van-overlay>
    <van-overlay :show="androidSubShow" z-index="999" class="androidSubOverlay">
      <div class="androidSubPopupBox" @click.stop>
        <div class="pSubHeader">
          <div class="pSubBack" @click="androidSubShow = false"></div>
          <div class="pSubTitle"></div>
        </div>
        <div class="pSubMain" @touchmove.stop>
          <img :src="plan2" class="pSubImg" v-if="currentPlan == 2" />
          <img :src="plan3" class="pSubImg" v-else-if="currentPlan == 3" />
          <img :src="plan4" class="pSubImg" v-else-if="currentPlan == 4" />
          <img :src="plan5" class="pSubImg" v-else-if="currentPlan == 5" />
          <img :src="plan6" class="pSubImg" v-else-if="currentPlan == 6" />
          <img :src="plan7" class="pSubImg" v-else-if="currentPlan == 7" />
          <img :src="plan8" class="pSubImg" v-else-if="currentPlan == 8" />
          <img :src="plan9" class="pSubImg" v-else-if="currentPlan == 9" />
          <img :src="plan1" class="pSubImg" v-else />
        </div>
      </div>
    </van-overlay>
    <!-- 苹果提示框 -->
    <van-overlay :show="iosPromptShow" z-index="999" class="iosOverlay">
      <div>
        <div class="overlayContent" @click.stop>
          <div class="confirmBtnBox" @click="iosPromptShow = false"></div>
        </div>
      </div>
    </van-overlay>
    <van-popup v-model="showTip" class="downTip">
      <div class="downTipTitle">温馨提示</div>
      <div class="downTipContent">
        请使用Safari浏览器进行下载
      </div>
      <div class="url">{{ currUrl }}</div>
      <div class="downTipConfirm" @click.stop="doCopy">复制链接</div>
    </van-popup>
  </div>
</template>

<script>
export default {
  props: ["os"],
  data() {
    return {
      androidPromptShow: false,
      iosPromptShow: false,
      showTip: false,
      androidSubShow: false,
      currUrl: window.location.href,
      tabActive: 0,
      tabs: [
        {
          label: "a",
          value: "hot",
        },
        {
          label: "b",
          value: "darkweb",
        },
        {
          label: "c",
          value: "cartoon",
        },
      ],
      currentPlan: 0,
      plan1: require("./../../../assets/images/mobile1/plan1.webp"),
      plan2: require("./../../../assets/images/mobile1/plan2.webp"),
      plan3: require("./../../../assets/images/mobile1/plan3.webp"),
      plan4: require("./../../../assets/images/mobile1/plan4.webp"),
      plan5: require("./../../../assets/images/mobile1/plan5.webp"),
      plan6: require("./../../../assets/images/mobile1/plan6.webp"),
      plan7: require("./../../../assets/images/mobile1/plan7.webp"),
      plan8: require("./../../../assets/images/mobile1/plan8.webp"),
      plan9: require("./../../../assets/images/mobile1/plan9.webp"),
    };
  },
  mounted() {
    let ua = this.os.userAgent.toLowerCase();
    let isSafari =
      ua.indexOf("safari") !== -1 &&
      ua.indexOf("browser") === -1 &&
      ua.indexOf("android") === -1;
    // alert(ua)
    console.log(ua);
    console.log(isSafari);
    // console.log(ua.indexOf('safari'), "------");
    if (!isSafari && this.os.isPhone) {
      this.showTip = true;
      // console.log(this.showTip, '-------------')
      // alert('请使用Safari浏览器进行下载')
    }
    const referrer = sessionStorage.getItem("referrer");
    const dc = sessionStorage.getItem("dc");
    if (referrer && dc) {
      this.currUrl = referrer + "user.html?dc=" + dc;
    } else {
      if (referrer) {
        this.currUrl = referrer + "user.html";
      } else {
        this.currUrl = window.location.href;
      }
    }
  },
  methods: {
    // 剪贴板
    doCopy() {
      this.$copyText(this.currUrl).then(
        () => {
          console.log("复制成功");
          this.showTip = false;
        },
        () => {
          console.log("复制失败");
        }
      );
    },
    async showInstallTutorial(type) {
      if (type == "ios") {
        this.iosPromptShow = true;
      } else {
        this.androidPromptShow = true;
      }
    },
    async getApkInfo() {
      let type = "android";
      if (this.os.isPhone) {
        type = "ios";
      } else {
        type = "android";
      }
      await this.showInstallTutorial(type);
      setTimeout(() => {
        this.$emit("getApkInfo", type);
      }, 500);
    },
    showPlan(val) {
      this.currentPlan = val;
      this.androidSubShow = true;
    },
    toTg() {
      window.open("https://t.me/loliidao");
    },
    toBusiness() {
      window.open("https://t.me/laiango2naduo");
    },
    toPotato() {
      window.open("https://dlptm.org/loliidao");
    },
  },
};
</script>

<style lang="scss" scoped>
.mobilePage {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  .main {
    height: 805.5px;
    width: 100%;
    background: url("./../../../assets/images/mobile1/bg.webp") no-repeat;
    background-size: 100% 100%;
    position: relative;
    .bg {
      width: 100%;
    }
    .headerBox {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 20px;
      position: fixed;
      left: 0;
      top: 0;
      z-index: 9999;
      .logo {
        height: 40px;
        width: 122px;
        background: url("./../../../assets/images/mobile1/logo.webp") no-repeat;
        background-size: 100% 100%;
      }
      .contactBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 41.5px;
        width: 90px;
        background: url("./../../../assets/images/mobile1/contactBox.png")
          no-repeat;
        background-size: 100% 100%;
        position: absolute;
        right: 20px;
        z-index: 999;
        div {
          height: 41.5px;
          width: 40px;
        }
      }
    }
    .bottomBox {
      height: 35.9px;
      width: 270px;
      position: absolute;
      left: 50%;
      top: 582px;
      transform: translate(-50%, 0);
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto;
      .iosBtn {
        height: 35.9px;
        width: 118px;
        background: url("../../../assets/images/mobile1/iosBtn.png") no-repeat;
        background-size: 100% 100%;
      }
      .androidBtn {
        height: 35.9px;
        width: 118px;
        background: url("../../../assets/images/mobile1/androidBtn.png")
          no-repeat;
        background-size: 100% 100%;
      }
      // div {
      //   height: 100%;
      //   width: 118px;
      // }
    }
    .bottomText {
      position: absolute;
      left: 50%;
      top: 700px;
      transform: translate(-50%, 0);
      height: 16px;
      width: 270px;
      background: url("../../../assets/images/mobile1/bottomText.png") no-repeat;
      background-size: 100% 100%;
      margin: 10px auto 0;
    }
  }
  .androidOverlay {
    height: 100%;
    .androidPopupBox {
      background: white;
      height: 100%;
      .pHeader {
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        .pBack {
          width: 20px;
          height: 20px;
          background: url("./../../../assets/images/mobile1/back.png") no-repeat;
          background-size: 100% 100%;
          position: absolute;
          left: 16px;
          top: 12px;
        }
        .pTitle {
          width: 150px;
          height: 18px;
          background: url("./../../../assets/images/mobile1/pTitle.png")
            no-repeat;
          background-size: 100% 100%;
        }
      }
      .pMain {
        height: calc(100% - 44px);
        overflow-y: auto;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
        padding: 29px 0 25px;
        .overlayContent {
          width: 360px;
          height: 715px;
          background: url("./../../../assets/images/mobile1/androidPopupBg.webp")
            no-repeat;
          background-size: 100% 100%;
          margin: 0 auto;
          position: relative;
          .btnBox {
            height: 75px;
            width: 360px;
            margin: 0 auto 5px;
            position: relative;
            z-index: 1;
          }
        }
      }
    }
  }
  .androidSubOverlay {
    height: 100%;
    .androidSubPopupBox {
      background: white;
      height: 100%;
      .pSubHeader {
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        .pSubBack {
          width: 20px;
          height: 20px;
          background: url("./../../../assets/images/mobile1/back.png") no-repeat;
          background-size: 100% 100%;
          position: absolute;
          left: 16px;
          top: 12px;
        }
        .pSubTitle {
          width: 150px;
          height: 18px;
          background: url("./../../../assets/images/mobile1/pTitle.png")
            no-repeat;
          background-size: 100% 100%;
        }
      }
      .pSubMain {
        height: calc(100% - 44px);
        overflow-y: auto;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
        padding: 45px 0 50px;
        .pSubImg {
          width: 100vw;
        }
      }
    }
  }
  .iosOverlay {
    display: flex;
    align-items: center;
    justify-content: center;
    .overlayContent {
      width: 297px;
      height: 441px;
      background: url("./../../../assets/images/mobile1/iosTip.webp") no-repeat;
      background-size: 100% 100%;
      border-radius: 12px;
      padding-top: 380px;
      .confirmBtnBox {
        height: 36px;
        width: 140px;
        border-radius: 40px;
        margin: 0 auto;
      }
    }
  }
}
.downTip {
  border-radius: 10px;
  padding: 18px;
  box-sizing: border-box;
  text-align: center;
  .downTipTitle {
    font-size: 18px;
    margin-bottom: 34px;
  }
  .downTipContent {
    white-space: nowrap;
    font-size: 15px;
  }
  .url {
    font-size: 15px;
    margin-top: 16px;
    text-align: left;
  }
  .downTipConfirm {
    // margin-top: 12px;
    font-size: 15px;
    width: 160px;
    height: 30px;
    border-radius: 40px;
    margin: 32px auto 0;
    color: #fff;
    background: #5b92ee;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
